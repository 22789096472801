import React, {Component} from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';

import {BrowserRouter, Route, Switch} from "react-router-dom";
import {createBrowserHistory} from 'history';

import Home from "./components/Home";
import Signin from './components/Signin';
import UploadRandomQuestions from './components/UploadRandomQuestions'
import Settings from './components/Settings'

export default class App extends Component {

    constructor() {
        super();
        this.history = createBrowserHistory();
    }

    render() {
        return (
            <BrowserRouter>
                <div>
                    <Switch history={this.history}>
                        <Route path="/" component={() => <Signin/>} exact/>
                        <Route path="/home" component={() => <Home/>} exact/>
                        <Route path="/uploadRandomQuestions" component={() => <UploadRandomQuestions/>} exact/>
                        <Route path="/settings" component={() => <Settings/>} exact/>
                    </Switch>
                </div>
            </BrowserRouter>
        );
    }
}