import React, {Component} from 'react';

import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux';

import store from '../store';

import {populateQuestions, getQuestions} from '../actions/questionActions'

class UploadRandomQuestions extends Component {

    constructor() {
        super();
        this.onTextAreaChange = this.onTextAreaChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onBack = this.onBack.bind(this);

        this.state = {
            questions: ''
        }
    }

    onTextAreaChange(event) {
        this.setState({
            ...this.state,
            questions: event.target.value
        });
    }

    onSubmit(event) {
        this.props.populateQuestions(this.state.questions, () => {
            this.props.getQuestions(() => {
                console.log("populated questions and going back to the previous page");
                this.props.history.goBack();
            })
        });
    }

    onBack() {
        this.props.history.goBack();
    }

    render() {

        return (
            <div className="container-fluid topcontainer">
                <div className="row">
                    <div className="col-12">
                        <textarea className="form-control" rows="12" onChange={this.onTextAreaChange}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-1">
                     <button type="button" className="btn btn-primary" onClick={this.onSubmit}>Submit</button>
                    </div>
                    <div className="col-1">
                        <button className="btn btn-primary" onClick={this.onBack}>Back</button>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        populateQuestions: (questions, callback) => dispatch(populateQuestions(questions, callback)),
        getQuestions: (callback) => dispatch(getQuestions(callback)),
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UploadRandomQuestions));