import Queue from '../queue/Queue';

export default class QuestionsMerger {

    /*
     The idea is that if we have two subcategories that are checked for example, one that has 100 questions (category A)
     and the other one has 200 questions (category B), I want to display 1 question from the A followed by 2 questions
     from category B, until all the 300 questions from both categories are displayed.

     Here is a real life example:

     suppose we have three categories:
     category #1: 300 questions
     category #2: 100 questions
     category #3: 7 questions

     total number of questions = 407

     Calculate the percentage of each category:

     category #1: 300/407 = 73.71%
     category #2: 100/407 = 24.57%
     category #3: 7/407 = 1.72%

     Smallest percentage: 1.72%

     Calculate the number of consecutive questions: percentage / smallest percentage

     category #1: 1.72   -> 1.72/1.72  = 1
     category #2: 24.57  -> 24.57/1.72 = 14
     category #3: 73.71  -> 73.71/1.72 = 42

     */

    constructor(questions) {
        this.questions = questions;
    }

    mergeQuestionsBasedOnSubcategoryPercentage() {

        const subcategoryCountMap = this.getSubcategoryCountMap();
        const subcategoryPercentageMap = this.getSubcategoryPercentageMap(subcategoryCountMap);
        const numberOfConsecutiveQuestionsMap = this.getNumberOfConsecutiveQuestionsMap(subcategoryPercentageMap);
        const subcategoryQuestionsQueueMap = this.getSubcategoryQuestionsQueueMap();
        const mergedQuestions = this.getMergedQuestions(numberOfConsecutiveQuestionsMap, subcategoryQuestionsQueueMap);

        return mergedQuestions;
    }

    /**
     * subcategory -> count map.
     *
     * @returns {Map}
     */
    getSubcategoryCountMap() {

        const subcategoryCountMap = new Map();

        this.questions.forEach(question => {

            const subcateogry = question.category+"."+question.subcategory;

            if (subcategoryCountMap.has(subcateogry) ) {
                const count = subcategoryCountMap.get(subcateogry);
                subcategoryCountMap.set(subcateogry, count+1);
            }
            else {
                subcategoryCountMap.set(subcateogry, 1);
            }
        });

        return subcategoryCountMap;
    }

    getSubcategoryPercentageMap(subcategoryCountMap) {

        const subcategoryPercentageMap = new Map();

        let totalNumberOfQuestions = 0;
        for (let subcategoryCount of subcategoryCountMap.values()) {
            totalNumberOfQuestions += subcategoryCount;
        }

        subcategoryCountMap.forEach((count, subcategory) => {

            subcategoryPercentageMap.set(subcategory, count/totalNumberOfQuestions);
        });

        return subcategoryPercentageMap;
    }

    getNumberOfConsecutiveQuestionsMap(subcategoryPercentageMap) {

        const numberOfConsecutiveQueestionsMap = new Map();

        const smallestPercentage = this.getSmallestPercentage(subcategoryPercentageMap);

        subcategoryPercentageMap.forEach((percentage, subcategory) => {
            numberOfConsecutiveQueestionsMap.set(subcategory, percentage/smallestPercentage);
        });

        return numberOfConsecutiveQueestionsMap;
    }

    getSubcategoryQuestionsQueueMap() {

        const subcategoryQuestionsQueueMap = new Map();

        this.questions.forEach(question => {

            const subcateogry = question.category+"."+question.subcategory;

            if (subcategoryQuestionsQueueMap.has(subcateogry)) {
                const queue = subcategoryQuestionsQueueMap.get(subcateogry);
                queue.enqueue(question);
            }
            else {
                const queue = new Queue();
                queue.enqueue(question);
                subcategoryQuestionsQueueMap.set(subcateogry, queue);
            }
        });

        return subcategoryQuestionsQueueMap;
    }

    getMergedQuestions(subcategoryNumberOfConsecutiveQuestionsMap, subcategoryQuestionsQueueMap) {

        const mergedQuestions = [];

        do {
            subcategoryNumberOfConsecutiveQuestionsMap.forEach((numberOfConsecutiveQuestions, subcategory) => {

                const queue = subcategoryQuestionsQueueMap.get(subcategory);

                for (let i = 0; i < numberOfConsecutiveQuestions; i++) {

                    const question = queue.dequeue();

                    if (question !== null) {
                        mergedQuestions.push(question);
                    }
                }
            });
        }
        while (!this.allQueuesAreEmpty(subcategoryQuestionsQueueMap));

        return mergedQuestions;
    }

    allQueuesAreEmpty(subcategoryQuestionsQueueMap) {

        for (let queue of subcategoryQuestionsQueueMap.values()) {

            if (!queue.isEmpty()) {
                return false;
            }
        }

        return true;
    }

    getSmallestPercentage(subcategoryPercentageMap) {

        let smallesttPercentage = 2;

        for (let percentage of subcategoryPercentageMap.values()) {
            if (percentage < smallesttPercentage) {
                smallesttPercentage = percentage;
            }
        }

        return smallesttPercentage;
    }
}