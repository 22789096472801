import React, {Component} from 'react';

import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux';

import {setSettingsInitialized, setCheckedPriorities, setAllCategories,
        setSearchText, setRandomize,
        setCheckedCategories, setCheckedSubcategories} from '../actions/questionActions'

class Settings extends Component {

    constructor() {
        super();
        this.onPriorityChange = this.onPriorityChange.bind(this);
        this.onAllCategoriesChange = this.onAllCategoriesChange.bind(this);
        this.updateCategoriesAndSubcategoriesBasedonAllCategories = this.updateCategoriesAndSubcategoriesBasedonAllCategories.bind(this);
        this.updateSubcategoriesBasedOnCategories = this.updateSubcategoriesBasedOnCategories.bind(this);

        this.onSearch = this.onSearch.bind(this);
        this.onRandomizeChange = this.onRandomizeChange.bind(this);
        this.onCategoriesChange = this.onCategoriesChange.bind(this);
        this.onSubcategoriesChange = this.onSubcategoriesChange.bind(this);
        this.onBack = this.onBack.bind(this);

        this.state = {
            categories: [],
            categoriesMap: new Map(),
            priorities: [],
            searchText: '',
            allCategoriesChecked: true,
            randomize: true,
            checkedPriorities: new Map(),
            checkedCategories: new Map(),
            checkedSubcategories: new Map(),
        }
    }

    componentDidMount() {
        const categoriesMap = this.getCategoriesMap();
        const categories = this.getCategories(categoriesMap);
        const priorities = this.getPriorities();

        this.setState({
            ...this.state,
            categories,
            categoriesMap,
            priorities,
            searchText: this.props.searchText,
            allCategoriesChecked: this.props.allCategoriesChecked,
            randomize: this.props.randomize,
            checkedPriorities: this.props.checkedPriorities,
            checkedCategories: this.props.checkedCategories,
            checkedSubcategories: this.props.checkedSubcategories,
        }, () => {
            if (!this.props.settingsInitialized) {
                const checkedPriorities = this.setCheckedPrioritiesToTrue(priorities);
                this.updateCategoriesAndSubcategoriesBasedonAllCategories(true, checkedPriorities);
                this.props.setSettingsInitialized(true);
            }
        });
    }

    setCheckedPrioritiesToTrue(priorities) {

        let checkedPriorities = new Map();

        priorities.forEach(priority => {
            checkedPriorities.set(priority, true);
        });

       return checkedPriorities;
    }

    getPriorities() {
        let prioritiesSet = new Set();

        this.props.questions.forEach(question => {
            prioritiesSet.add(question.priority);
        });

        let prioritiesArray = [];
        prioritiesSet.forEach(priority => {
            prioritiesArray.push(priority);
        });

        prioritiesArray.sort();

        return prioritiesArray;
    }

    getCategoriesMap() {

        const categoryToSubcategoriesSetMap = new Map();

        this.props.questions.forEach(question => {
            if (categoryToSubcategoriesSetMap.has(question.category)) {
                let set = categoryToSubcategoriesSetMap.get(question.category);
                set.add(question.subcategory);
            }
            else {
                let set = new Set();
                categoryToSubcategoriesSetMap.set(question.category, set);
                set.add(question.subcategory);
            }
        });

        // convert set to array and sort
        const categoryToSubcategoriesArrayMap = new Map();
        for (let entry of categoryToSubcategoriesSetMap.entries()) {
            const category = entry[0];
            const subcategoriesSet = entry[1];

            let subcategoriesArray = new Array();

            subcategoriesSet.forEach(subcategory => {
                subcategoriesArray.push(subcategory);
            });

            subcategoriesArray.sort((a, b) => {
                return a.toLowerCase().localeCompare(b.toLowerCase());
            });
            categoryToSubcategoriesArrayMap.set(category, subcategoriesArray);
        };

        return categoryToSubcategoriesArrayMap;
    }

    getCategories(categoriesMap) {

        let categories = [];

        for (let category of categoriesMap.keys()) {
            categories.push(category);
        }

        categories.sort();
        return categories;
    }

    onPriorityChange(event) {

        let checkedPriorities = new Map(this.props.checkedPriorities);
        checkedPriorities.set(event.target.value, event.target.checked);

        this.setState({
            ...this.state,
            checkedPriorities
        }, () => {
            this.props.setCheckedPriorities(this.state.checkedPriorities);
        });
    }

    onRandomizeChange(event) {

        const randomize = event.target.checked;

        this.setState({
            ...this.state,
            randomize,
        }, () => {
            this.props.setRandomize(this.state.randomize);
        });
    }

    onSearch(event) {
        let searchText = event.target.value;

        this.setState({
            ...this.state,
            searchText,
        }, () => {
            this.props.setSearchText(this.state.searchText);
        });
    }

    onAllCategoriesChange(event) {
        this.updateCategoriesAndSubcategoriesBasedonAllCategories(event.target.checked, this.state.checkedPriorities);
    }

    updateCategoriesAndSubcategoriesBasedonAllCategories(checked, checkedPriorities) {

        let checkedCategories = new Map();
        let checkedSubcategories = new Map();

        this.state.categories.forEach(category => {
            checkedCategories.set(category, checked);

            this.state.categoriesMap.get(category).forEach(subcategory => {
                checkedSubcategories.set(category+"."+subcategory, checked);
            });
        });

        this.setState({
            ...this.state,
            checkedPriorities,
            allCategoriesChecked: checked,
            checkedCategories,
            checkedSubcategories,
        }, () => {
            this.props.setAllCategories(this.state.allCategoriesChecked);
            this.props.setCheckedCategories(this.state.checkedCategories);
            this.props.setCheckedSubcategories(this.state.checkedSubcategories);
            this.props.setCheckedPriorities(this.state.checkedPriorities);
        });
    }

    onCategoriesChange(event) {

        this.updateSubcategoriesBasedOnCategories(event.target.value, event.target.checked);
    }

    updateSubcategoriesBasedOnCategories(category, checked) {

        let checkedCategories = new Map(this.state.checkedCategories);
        checkedCategories.set(category, checked);

        let checkedSubcategories = new Map(this.state.checkedSubcategories);
        this.state.categoriesMap.get(category).forEach(subcategory => {
            checkedSubcategories.set(category+"."+subcategory, checked);
        });

        this.setState({
            ...this.state,
            checkedCategories,
            checkedSubcategories,
        }, () => {
            this.props.setCheckedCategories(this.state.checkedCategories);
            this.props.setCheckedSubcategories(this.state.checkedSubcategories);
        });
    }

    onSubcategoriesChange(event) {
        const subcategory = event.target.value;
        const checked = event.target.checked;

        let checkedSubcategories = new Map(this.state.checkedSubcategories);
        checkedSubcategories.set(subcategory, checked);

        this.setState({
            ...this.state,
            checkedSubcategories,
        }, () => {
            this.props.setCheckedSubcategories(this.state.checkedSubcategories);
        });
    }

    onBack() {
        this.props.history.goBack();
    }

    getRows() {
        let rows = [];

        rows.push(<hr key="hr1"></hr>);

        rows.push(<div key="search" className="row" style={{color: "blue"}}>
            <div className="col-1">
                <button className="btn btn-primary" onClick={this.onBack}>Back</button>
            </div>
            <div className="col-4">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    onChange={this.onSearch}
                    style={{width: "100%"}}
                    id="search"
                    value={this.state.searchText}
                />
            </div>
        </div>);

        rows.push(<hr key="hr2"></hr>);

        rows.push(<div key="Randomize" className="row" style={{color: "blue"}} >
            <div className="col-5">
                Randomize
            </div>
            <div className="col-1">
                <input className="form-check-input" type="checkbox" value="Randomize" key="Randomize" onChange={this.onRandomizeChange}
                       checked={this.state.randomize}/>
            </div>
        </div>);

        rows.push(<hr key="hr3"></hr>);

        this.state.priorities.map(priority => {

            rows.push(<div key={priority} className="row" style={{color: "red"}}>
                        <div className="col-5">
                            {priority}
                        </div>
                        <div className="col-1">
                            <input className="form-check-input" type="checkbox" value={priority} key={priority} onChange={this.onPriorityChange}
                                   checked={this.state.checkedPriorities.has(priority) && this.state.checkedPriorities.get(priority)} />
                        </div>
                      </div>);
        });

        rows.push(<hr key="hr4"></hr>);

        rows.push(<div key="All Categories" className="row" style={{color: "blue"}}>
            <div className="col-5">
                All Categories
            </div>
            <div className="col-1">
                <input className="form-check-input" type="checkbox" value="All Categories" key="All Categories"
                       onChange={this.onAllCategoriesChange}
                       checked={this.state.allCategoriesChecked}/>
            </div>
        </div>);

        this.state.categories.map(category => {

            rows.push(<div key={category} className="row">
                <div className="col-5">
                    {category}
                </div>
                <div className="col-1">
                    <input className="form-check-input" type="checkbox" value={category} key={category}
                           onChange={this.onCategoriesChange}
                           checked={(this.state.checkedCategories.has(category) && this.state.checkedCategories.get(category))}/>
                </div>
            </div>);


            this.state.categoriesMap.get(category).map(subcategory => {

                const subcatetoryName = category+"."+subcategory;

                rows.push(<div key={subcatetoryName} className="row">
                    <div className="col-1"> </div>
                    <div className="col-4">
                        {subcategory}
                    </div>
                    <div className="col-1">
                        <input className="form-check-input" type="checkbox" value={subcatetoryName} key={subcatetoryName}
                               onChange={this.onSubcategoriesChange}
                               checked={ (this.state.checkedSubcategories.has(subcatetoryName) && this.state.checkedSubcategories.get(subcatetoryName))}/>
                    </div>
                </div>);
            });
        });

        return rows;
    }

    render() {

        return (
            <div className="container-fluid topcontainer">
                {
                    this.getRows().map(row => {
                        return row;
                    })
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        questions: state.questionReducer.questions,
        settingsInitialized: state.questionReducer.settingsInitialized,
        checkedPriorities: state.questionReducer.checkedPriorities,
        allCategoriesChecked: state.questionReducer.allCategoriesChecked,
        searchText: state.questionReducer.searchText,
        randomize: state.questionReducer.randomize,
        checkedCategories: state.questionReducer.checkedCategories,
        checkedSubcategories: state.questionReducer.checkedSubcategories,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setSettingsInitialized: (settingsInitialized) => dispatch(setSettingsInitialized(settingsInitialized)),
        setCheckedPriorities: (checkedPriorities) => dispatch(setCheckedPriorities(checkedPriorities)),
        setAllCategories: (allCategoriesChecked) => dispatch(setAllCategories(allCategoriesChecked)),
        setSearchText: (searchText) => dispatch(setSearchText(searchText)),
        setRandomize: (randomize) => dispatch(setRandomize(randomize)),
        setCheckedCategories: (checkedCategories) => dispatch(setCheckedCategories(checkedCategories)),
        setCheckedSubcategories: (checkedSubcategories) => dispatch(setCheckedSubcategories(checkedSubcategories)),
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Settings));