import axios from "axios";
import querystring from 'querystring';

export function getQuestions(callback) {

    return function (dispatch) {

        axios.get('/questions')
            .then((response) => {

                dispatch({
                    type: 'GOT_QUESTIONS',
                    payload: {
                        questions: response.data
                    }
                });

                if (callback !== undefined)
                    dispatch(callback);
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

export function setSettingsInitialized(settingsInitialized) {

    return function (dispatch) {

        dispatch({
            type: 'SETTINGS_INITIALIZED',
            payload: {
                settingsInitialized
            }
        });
    }
}
export function populateQuestions(questions, callback) {

    return function (dispatch) {

        axios.post('/questions', querystring.stringify({
            questions
        }))
            .then((response) => {

                dispatch({
                    type: 'POPULATED_QUESTIONS',
                });

                if (callback !== undefined)
                    dispatch(callback);
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

export function setCheckedPriorities(checkedPriorities) {

    return function (dispatch) {

        dispatch({
            type: 'CHECKED_PRIORITIES',
            payload: {
                checkedPriorities
            }
        });
    }
}

export function setAllCategories(allCategoriesChecked) {

    return function (dispatch) {

        dispatch({
            type: 'ALL_CATEGORIES_CHECKED',
            payload: {
                allCategoriesChecked
            }
        });
    }
}

export function setSearchText(searchText) {

    return function (dispatch) {

        dispatch({
            type: 'SEARCH_TEXT_DATA',
            payload: {
                searchText
            }
        });
    }
}

export function setRandomize(randomize) {

    return function (dispatch) {

        dispatch({
            type: 'RANDOMIZE_CHECKED',
            payload: {
                randomize
            }
        });
    }
}

export function setCheckedCategories(checkedCategories) {

    return function (dispatch) {

        dispatch({
            type: 'CHECKED_CATEGORIES',
            payload: {
                checkedCategories
            }
        });
    }
}

export function setCheckedSubcategories(checkedSubcategories) {

    return function (dispatch) {

        dispatch({
            type: 'CHECKED_SUBCATEGORIES',
            payload: {
                checkedSubcategories
            }
        });
    }
}