import axios from "axios";
import querystring from 'querystring';

export function signin(username, password, callback) {

    return function (dispatch) {

        axios.get('/login', {
            headers: {
                Authorization: "Basic " + btoa(username+":"+password),
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then((response) => {

                if (callback !== undefined)
                    dispatch(callback);
            })
            .catch((error) => {
                console.log(error);
            });
    }
}