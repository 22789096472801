import React, {Component} from 'react';

import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux';

import {getQuestions} from '../actions/questionActions'

import QuestionsMerger from '../common/questionsmerger/QuestionsMerger'

class Home extends Component {

    constructor() {
        super();
        this.onKeyDown = this.onKeyDown.bind(this);
        this.shuffleQuestions = this.shuffleQuestions.bind(this);
        this.setQuestionsAndAnswerForwardStack = this.setQuestionsAndAnswerForwardStack.bind(this);
        this.nextQuestion = this.nextQuestion.bind(this);
        this.previousQuestion = this.previousQuestion.bind(this);

        this.questionsAndAnswersForwardStack = [];
        this.questionsAndAnswersBackwardStack = [];

        this.state = {
            questionOrAnswer: ''
        }
    }

    componentDidMount() {

        if (this.props.questions.length === 0) {
            this.props.getQuestions(() => {
                this.filterQuestions();
            })
        }
        else {
            this.filterQuestions();
        }
    }

    filterQuestions() {
        let questions = this.filterQuestionsByPriorities();
        questions = this.filterQuestionByAllCategories(questions);

        if (!this.props.randomize) {
            const questionsMerger = new QuestionsMerger(questions);
            questions = questionsMerger.mergeQuestionsBasedOnSubcategoryPercentage();
        }

        questions = this.filterBySearchText(questions);
        questions = this.shuffleQuestions(questions);
        this.setQuestionsAndAnswerForwardStack(questions);
    }

    filterQuestionsByPriorities() {

        let questions = this.props.questions.filter(question => {
            if (this.props.checkedPriorities.size === 0)
                return true;

            return this.props.checkedPriorities.get(question.priority);
        });

        return questions;
    }

    filterQuestionByAllCategories(questions) {

        if (this.props.allCategoriesChecked) {
            return questions;
        }

        const filteredQuestions = questions.filter(question => {
            return this.props.checkedCategories.get(question.category) || this.props.checkedSubcategories.get(question.category+"."+question.subcategory);
        });

        return filteredQuestions;
    }

    filterBySearchText(questions) {

        if (this.props.searchText.length > 0) {
            const lowerCaseSearchText = this.props.searchText.toLowerCase();

            const filteredQuestions = questions.filter(question => {

                return question.question.toLowerCase().includes(lowerCaseSearchText) ||
                       question.answer.toLowerCase().includes(lowerCaseSearchText) ||
                       question.category.toLowerCase().includes(lowerCaseSearchText) ||
                       question.subcategory.toLowerCase().includes(lowerCaseSearchText);
            });

            return filteredQuestions;
        }

        return questions;
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown);
    }

    shuffleQuestions(questions) {

        if (this.props.randomize) {
            for (let i = 1; i < questions.length; i++) {
                const randomIndex = Math.floor(Math.random() * i);
                const temp = questions[i];
                questions[i] = questions[randomIndex];
                questions[randomIndex] = temp;
            }
        }

        return questions;
    }

    setQuestionsAndAnswerForwardStack(questions) {

        // stack revereses the order, so I need to put them in a reverse order so that when the stack
        // reverses the order, it will be the correct order.
        let reverseOrderedQuestions = [...questions];
        reverseOrderedQuestions = reverseOrderedQuestions.reverse();

        let reverseCounter = reverseOrderedQuestions.length-1;
        for (let i = 0; i < reverseOrderedQuestions.length; i++) {
            const question = reverseOrderedQuestions[i];
            this.questionsAndAnswersForwardStack.push('Answer: ' + question.answer);

            this.questionsAndAnswersForwardStack.push('Question: ' + (reverseCounter+1) + '/' + reverseOrderedQuestions.length + '- ' + question.question);

            reverseCounter--;
        }

        document.addEventListener("keydown", this.onKeyDown);
    }

    onKeyDown(event) {

        let charCode = String.fromCharCode(event.which).toLowerCase();

        if (event.key === 'Backspace' || event.key === 'ArrowLeft') {
            this.previousQuestion();
        }
        else if (event.key === 'S' || event.key === 's') {

                this.props.history.push('/settings');
                return;
            }
        else if (event.key === 'L' || event.key === 'l') {

                this.props.history.push('/uploadRandomQuestions');
                return;
        }
        else if (event.ctrlKey && (charCode === 'c' || charCode === 'C') ) {
            // do nothing, to allow copying to
        }
        else if (!event.ctrlKey) {
            this.nextQuestion();
        }
    }

    nextQuestion() {

        if (this.questionsAndAnswersForwardStack.length > 0) {

            const questionOrAnswer = this.questionsAndAnswersForwardStack.pop();
            this.questionsAndAnswersBackwardStack.push(questionOrAnswer);

            if (questionOrAnswer === this.state.questionOrAnswer) {
                this.nextQuestion();
            }
            else {
                this.setState({
                    ...this.state,
                    questionOrAnswer
                });
            }
        }
    }

    previousQuestion() {

        if (this.questionsAndAnswersBackwardStack.length > 0) {

            const questionOrAnswer = this.questionsAndAnswersBackwardStack.pop();
            this.questionsAndAnswersForwardStack.push(questionOrAnswer);

            if (questionOrAnswer === this.state.questionOrAnswer) {
                this.previousQuestion();
            }
            else {
                this.setState({
                    ...this.state,
                    questionOrAnswer,
                });
            }
        }

    }

    render() {
        let color;
        let questionOrAnswer;

        if (this.state.questionOrAnswer.includes('Question: ')) {
            color = 'blue';
            questionOrAnswer = this.state.questionOrAnswer.substring(10, this.state.questionOrAnswer.length);
        }
        else {
            color = 'black';
            questionOrAnswer = this.state.questionOrAnswer.substring(8, this.state.questionOrAnswer.length);
        }

        return (
            questionOrAnswer.split('\n').map(value => {
                return <p key={Math.random()} style={{color}}>{value}</p>
            })
        );
    }
}

function mapStateToProps(state) {
    return {
        questions: state.questionReducer.questions,
        checkedPriorities: state.questionReducer.checkedPriorities,
        allCategoriesChecked: state.questionReducer.allCategoriesChecked,
        searchText: state.questionReducer.searchText,
        randomize: state.questionReducer.randomize,
        checkedCategories: state.questionReducer.checkedCategories,
        checkedSubcategories: state.questionReducer.checkedSubcategories,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getQuestions: (callback) => dispatch(getQuestions(callback)),
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Home));