import Node from './Node'

export default class Queue {

    constructor() {
        this.first = null;
        this.last = null;
        this.size = 0;
    }

    enqueue(value) {

        const node = new Node(value);

        if (this.isEmpty()) {
            this.first = node;
            this.last = node;
            this.size++;
            return;
        }

        const prevLast = this.last;
        this.last = node;
        prevLast.setNext(this.last);
        this.size++;
    }

    dequeue() {

        if (this.isEmpty()) {
            return null;
        }

        this.size--;
        const prevFirst = this.first;
        this.first = prevFirst.next;
        return prevFirst.value;
    }

    isEmpty() {
        return this.first === null;
    }

    getSize() {
        return this.size;
    }
}