
const initialState = {
    questions: [],
    settingsInitialized: false,
    checkedPriorities: new Map(),
    allCategoriesChecked: true,
    searchText: '',
    randomize: true,
    checkedCategories: new Map(),
    checkedSubcategories: new Map(),
};

export default function reducer(state = initialState, action) {

    switch (action.type) {

        case 'GOT_QUESTIONS':

            return {
                ...state,
                questions: action.payload.questions,
            }

        case 'SETTINGS_INITIALIZED': {
            return {
                ...state,
                settingsInitialized: action.payload.settingsInitialized
            }
        }

        case 'CHECKED_PRIORITIES':

            return {
                ...state,
                checkedPriorities: action.payload.checkedPriorities,
            }

        case 'ALL_CATEGORIES_CHECKED':
            return {
                ...state,
                allCategoriesChecked: action.payload.allCategoriesChecked
            }

        case 'SEARCH_TEXT_DATA':
            return {
                ...state,
                searchText: action.payload.searchText
            }

        case 'RANDOMIZE_CHECKED':
            return {
                ...state,
                randomize: action.payload.randomize
            }

        case 'CHECKED_CATEGORIES':
            return {
                ...state,
                checkedCategories: action.payload.checkedCategories
            }

        case 'CHECKED_SUBCATEGORIES':
            return {
                ...state,
                checkedSubcategories: action.payload.checkedSubcategories
            }
    };

    return state;
}