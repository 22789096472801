export default class Node {

    constructor(value) {
        this.value = value;
        this.next = null;
    }

    setValue(value) {
        this.value = value;
    }

    setNext(next) {
        this.next = next;
    }
}