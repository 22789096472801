import React, {Component} from 'react';

import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux';
import {signin} from '../actions/userActions'

import store from '../store';

class Signin extends Component {

    constructor() {
        super();
        this.onSubmit = this.onSubmit.bind(this);
        this.onUsernameChange = this.onUsernameChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);

        this.state = {
            username: '',
            password: ''
        }
    }

    onUsernameChange(event) {
        this.setState({
            ...this.state,
            username: event.target.value
        });
    }

    onPasswordChange(event) {
        this.setState({
            ...this.state,
            password: event.target.value
        });
    }

    onSubmit(event) {

        event.preventDefault();

        event.target.blur(); // unfocus password input

        this.props.signin(this.state.username, this.state.password,
            () => {
                this.props.history.push('/home');
            });
    }

    render() {

        return (
            <div className="container-fluid topcontainer">

                <div className="row">
                    <div className="col-lg pl-lg-0 pr-lg-0 align-self-center pagebackgroundcolor" style={{height: 100}}>
                        &nbsp;
                    </div>
                </div>

                <div className="row pagebackgroundcolor">
                    <div className="col-lg-3 offset-lg-5 signinform">
                        <form>
                            <div className="form-group" style={{paddingTop: 30}}>
                                <b>Please enter your username & password</b>
                            </div>
                            <div className="form-group" style={{paddingTop: 20, paddingLeft: 50, paddingRight: 50}}>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter username"
                                    onChange={this.onUsernameChange}
                                    style={{width: "100%"}}
                                    autoFocus
                                />
                            </div>
                            <div className="form-group" style={{paddingLeft: 50, paddingRight: 50}}>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Password"
                                    onChange={this.onPasswordChange}
                                    style={{width: "100%"}}
                                />
                            </div>
                            <div className="form-group" style={{paddingLeft: 50, paddingRight: 50}}>
                                <button type="submit" className="btn btn-primary" onClick={this.onSubmit}
                                        style={{width: "100%"}}>
                                    Login
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        signin: (username, password, callback) => dispatch(signin(username, password, callback)),
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Signin));